// Import package
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Import file
import CircularProgress from "../../components/CircularProgress";
import { getAccount, getAccountTrainingCategories, saveAccount, saveNewAccount } from "../../appRedux/actions/Accounts";
import { Button, Card, Form, Input, PageHeader, Select } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toRupiah } from "../../libs";
import {
  ArrowRightOutlined,
} from "@ant-design/icons";

export default function NewAccountsPage() {
  const [trainingCategoriesOptions, setTrainingCategoriesOptions] = useState(null)
  const [form] = Form.useForm()
  const history = useHistory()

  useEffect(() => {
    getDataAccount()
  }, [])

  function getDataAccount() {
    getAccountTrainingCategories().then(data => {
      const options = []
      data.map((option) => {
        options.push({
          label: option.name,
          value: option.id
        })
      })
      setTrainingCategoriesOptions(options)
    })
  }

  async function onSubmit(values) {
    const response = await saveNewAccount(values)
    if (response)
      history.push("/accounts/")
  }

  return <>
      <PageHeader
        ghost={false}
        title={"Tambah Rekening"}
        onBack={() => history.push("/accounts/")}
      />
      <Card bordered={false} style={{ borderRadius: 0 }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
          }}
          preserve
          onFinish={(values) => { onSubmit(values) }}
          onValuesChange={(v) => { }}
        >
          <Form.Item
            name="name"
            label={"Nama Bank"}
            rules={[
              {
                required: true,
                message: "Mohon masukkan Nama Bank",
              },
            ]}
          >
            <Input type="text"/>
          </Form.Item>
          <Form.Item
            name="code"
            label={"Kode"}
            rules={[
              {
                required: true,
                message: "Mohon masukkan kode",
              },
            ]}
          >
            <Input type="text"/>
          </Form.Item>
          <Form.Item
            name="number"
            label={"Nomor Rekening"}
            rules={[
              {
                required: true,
                message: "Mohon masukkan nomor rekening dengan benar",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="owner_name"
            label={"Nama Pemilik"}
            rules={[
              {
                required: true,
                message: "Mohon masukkan nama pemilik",
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label={"Kategori Training"}
            name="training_categories_values"
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Pilih kategori training"
              options={trainingCategoriesOptions}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            <center>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                icon={<ArrowRightOutlined />}
              >
                Simpan
              </Button>
            </center>
          </Form.Item>
        </Form>
      </Card>
    </>
}
